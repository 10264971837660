import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; 
import { db } from '../firebaseConfig'; 
import { doc, setDoc, getDoc, collection, getDocs, deleteDoc  } from 'firebase/firestore';
import ChartComponent from './ChartComponent';
import './StockDetail.css';

const BASE_URL = 'https://eyeopeningstocks.com/backend';

const StockDetail = ({ data, onSearch }) => {
  const { stockName } = useParams();
  const { user } = useAuth(); 
  const [currentPrice, setCurrentPrice] = useState(null);
  const [targetPrice, setTargetPrice] = useState(null);
  const [volume, setVolume] = useState(null);
  const [peRatio, setPeRatio] = useState(null);
  const [dividendRate, setDividendRate] = useState(null);
  const [eps, setEps] = useState(null);
  const [bookValue, setBookValue] = useState(null);
  const [marketCap, setMarketCap] = useState(null);
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false); 
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isStockInPortfolio, setIsStockInPortfolio] = useState(false);
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [showCharts, setShowCharts] = useState(false);
  const [stockData, setStockData] = useState(null);
  const [upside, setUpside] = useState(null);
  const [news, setNews] = useState([]);
  const [showNews, setShowNews] = useState(false);

  const toggleView = () => {
    setShowCharts(prev => !prev);
  };
  

  // Modificar el useEffect inicial
  useEffect(() => {
    const fetchDataFromUrl = async () => {
      try {
        if (stockName) {
          const decodedStockName = decodeURIComponent(stockName);
          console.log('Stock name received:', decodedStockName);
          
          // Usar la URL completa del backend
          const response = await fetch(`${BASE_URL}/search/${encodeURIComponent(decodedStockName)}.xlsx`);
          
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          
          const data = await response.json();
          if (data && Array.isArray(data)) {
            setStockData(data);
            setError('');
          } else {
            setError("No data found for this stock");
            setStockData(null);
          }
        }
      } catch (err) {
        console.error("Error getting stock data from URL:", err);
        setError("Error getting stock data. Please try again later.");
        setStockData(null);
      }
    };

    if (data && Array.isArray(data) && data.length > 0) {
      setStockData(data);
      setError('');
    } else if (stockName) {
      fetchDataFromUrl();
    } else {
      setStockData(null);
      setError('No data available');
    }
  }, [stockName, data]);

  // Modificar el useEffect para filtrar datos
  useEffect(() => {
    if (stockData && Array.isArray(stockData)) {
      const newFilteredData = stockData.filter(sheet => 
        sheet && 
        typeof sheet === 'object' && 
        sheet.sheetName && 
        typeof sheet.sheetName === 'string' &&
        sheet.sheetName.toLowerCase() !== 'data' &&
        Array.isArray(sheet.data) && 
        sheet.data.length > 0
      );
      
      console.log('Filtered data:', newFilteredData);
      setFilteredData(newFilteredData);

      if (selectedSheetIndex >= newFilteredData.length) {
        setSelectedSheetIndex(0);
      }
    } else {
      setFilteredData([]);
    }
  }, [stockData, selectedSheetIndex]);

  // Modificar la extracción de datos del stock
  const stockInfo = useMemo(() => {
    const defaultInfo = { ticker: 'Ticker not available', companyName: 'Name not available' };
    
    if (!stockData || !Array.isArray(stockData)) {
      return defaultInfo;
    }
    
    const dataSheet = stockData.find(item => 
      item && 
      typeof item === 'object' && 
      item.sheetName === 'Data'
    );

    if (!dataSheet || !dataSheet.data) {
      return defaultInfo;
    }

    return {
      ticker: dataSheet.data[0]?.ticker?.[0] || 'Ticker not available',
      companyName: dataSheet.data[1]?.nombre?.[0] || 'Name not available'
    };
  }, [stockData]);

  // Usar stockInfo en lugar de las variables individuales
  const { ticker, companyName } = stockInfo;

  // Modificar el useEffect para obtener datos financieros
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        if (ticker && ticker !== 'Ticker not available') {
          setError(''); // Limpiar error anterior
          
          // Usar el endpoint del backend
          const response = await fetch(`https://eyeopeningstocks.com/backend/api/stocks/${ticker}`);

          if (!response.ok) {
            throw new Error('Error getting stock data');
          }

          const data = await response.json();

          // Actualizar estados con los datos recibidos
          if (data.price !== 'Not available') setCurrentPrice(parseFloat(data.price));
          if (data.volume !== 'Not available') setVolume(parseFloat(data.volume));
          if (data.peRatio !== 'Not available') setPeRatio(parseFloat(data.peRatio));
          if (data.dividendRate !== 'Not available') setDividendRate(parseFloat(data.dividendRate));
          if (data.eps !== 'Not available') setEps(parseFloat(data.eps));
          if (data.bookValue !== 'Not available') setBookValue(parseFloat(data.bookValue));
          if (data.marketCap !== 'Not available') setMarketCap(parseFloat(data.marketCap));

          // Calcular el upside
          if (targetPrice && data.price && data.price !== 'Not available') {
            const calculatedUpside = ((targetPrice - parseFloat(data.price)) / parseFloat(data.price)) * 100;
            setUpside(calculatedUpside);
          }

        }
      } catch (err) {
        console.error("Error getting stock data:", err);
        setError(`Error getting stock data: ${err.message}`);
        
        // Limpiar los estados en caso de error
        setCurrentPrice(null);
        setVolume(null);
        setPeRatio(null);
        setDividendRate(null);
        setEps(null);
        setBookValue(null);
        setMarketCap(null);
        setUpside(null);
      }
    };

    fetchStockData();
  }, [ticker, targetPrice]);

  useEffect(() => {
    const checkStockInPortfolio = async () => {
      if (user && ticker) {
        const stockDocRef = doc(collection(db, 'portfolios', user.uid, 'stocks'), ticker);
        const stockDoc = await getDoc(stockDocRef);
        if (stockDoc.exists()) {
          setIsStockInPortfolio(true);
        }
      }
    };

    checkStockInPortfolio();
  }, [user, ticker]); 


  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.isPremium) {
            user.isPremium = true;
          } else {
            user.isPremium = false;
          }
        }
      }
    };
  
    fetchUserData();
  }, [user]);
  

  
  // Modificar el useEffect para el Target Price
  useEffect(() => {
    if (stockData) {
      const dcfData = stockData.find(item => item.sheetName === 'DCF');
      if (dcfData) {
        const targetPriceRow = dcfData.data.find(row => Array.isArray(row) && row.includes('Target Price'));
        if (targetPriceRow && Array.isArray(targetPriceRow)) {
          const priceIndex = targetPriceRow.indexOf('Target Price');
          const price = parseFloat(targetPriceRow[priceIndex + 1]);
          if (!isNaN(price)) {
            setTargetPrice(price);
          }
        }
      }
    }
  }, [stockData]);

  // Manejo de selección de hojas
  const handleSheetSelect = (index) => {
    setSelectedSheetIndex(index);
  };

  // Formato de números con dos decimales
  const formatYahooNumber = (value) => {
    if (value === null || value === undefined || value === 'Not available') return 'N/A';
    if (typeof value === 'number') {
      if (Math.abs(value) >= 1e9) {
        return `${(value / 1e9).toFixed(2)}B`;
      }
      if (Math.abs(value) >= 1e6) {
        return `${(value / 1e6).toFixed(2)}M`;
      }
      if (Math.abs(value) >= 1e3) {
        return `${(value / 1e3).toFixed(2)}K`;
      }
      return value.toFixed(2);
    }
    return value;
  };

  // Limpiar valores, eliminando puntos y comas
  const cleanValue = (value) => {
    if (typeof value === 'string') {
        value = value.replace(/,/g, ''); 

        
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join('');
        }
    }
    return value;
};

  // Formateo de valores para su presentación
  const formatValue = (value, rowIndex) => {
    const cleanedValue = cleanValue(value);
    const isYear = /^\d{4}$/.test(cleanedValue); 

    if (isYear || rowIndex === 0) {
      return cleanedValue;
    }

    const num = parseFloat(cleanedValue);
    return !isNaN(num) ? num.toFixed(2) : cleanedValue;
  };

  // useEffect para noticias
  useEffect(() => {
    const fetchNews = async () => {
      if (ticker && ticker !== 'Ticker not available') {
        try {
          const response = await fetch(`https://eyeopeningstocks.com/backend/api/stocks/${ticker}/news`);
          if (response.ok) {
            const newsData = await response.json();
            setNews(newsData);
          }
        } catch (err) {
          console.error('Error fetching news:', err);
        }
      }
    };

    fetchNews();
  }, [ticker]);

  // Verificar si el usuario es premium o si aún tiene búsquedas disponibles
  const canViewDetails = () => {
    if (user && user.isPremium) return true;
    const searchCount = parseInt(localStorage.getItem('searchCount') || '0');
    return searchCount <= 10;
  };

  if (!stockData || !Array.isArray(stockData)) {
    return <div className="stock-detail-container">
      <div className="loading-message">
        {error || "Loading stock data..."}
      </div>
    </div>;
  }

  if (!filteredData || filteredData.length === 0) {
    return <div className="stock-detail-container">
      <div className="error-message">
        No data found to display
      </div>
    </div>;
  }

  // Asegurarse de que selectedSheet existe antes de usarlo
  const selectedSheet = filteredData[selectedSheetIndex] || filteredData[0];
  if (!selectedSheet) {
    return <div className="stock-detail-container">
      <div className="error-message">
        Error loading selected sheet
      </div>
    </div>;
  }

  // Filtrar datos de la hoja seleccionada
  const filteredSheetData = selectedSheet.data.filter(row => Array.isArray(row) ? row.length > 0 : Object.values(row).some(value => Array.isArray(value) && value.length > 0));

  
  const isDCF = selectedSheet.sheetName === "DCF";

  

  // Función para guardar datos en Firestore
  const handleToggleStockInPortfolio = async () => {
    if (!user) {
      alert('You must be authenticated to add or remove a stock from your portfolio.');
      return;
    }
  
    const userRef = doc(db, 'users', user.uid);
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        alert('User not found. Please try again.');
        return;
      }
  
      const userData = userDoc.data();
      const isPremium = userData.isPremium;
  
      if (!isPremium) {
        const userStocksRef = collection(db, 'portfolios', user.uid, 'stocks');
        const stockSnapshot = await getDocs(userStocksRef);
        const currentStocksCount = stockSnapshot.size;
  
        if (currentStocksCount >= 5 && !isStockInPortfolio) {
          alert('You have reached the limit of 5 stocks in your portfolio. Upgrade to premium to add more.');
          return;
        }
      }
  
      const stockData = {
        ticker: ticker,
        name: companyName,
        targetPrice: targetPrice,
      };
  
      const stockDocRef = doc(db, 'portfolios', user.uid, 'stocks', ticker);
      setLoadingSave(true);
  
      if (isStockInPortfolio) {
        await deleteDoc(stockDocRef);
        setIsStockInPortfolio(false);
        alert(`Stock ${ticker} has been removed from your portfolio.`);
      } else {
        await setDoc(stockDocRef, stockData);
        setIsStockInPortfolio(true);
        alert(`Stock ${ticker} has been added to your portfolio.`);
      }
  
      setLoadingSave(false);
    } catch (err) {
      console.error("Error saving stock to portfolio:", err);
      setLoadingSave(false);
      alert("Error saving stock to portfolio:" + (err.response?.data?.message || err.message));
    }
  };

  // Modificar la función de renderizado de la tabla
  const renderTableData = (sheetData) => {
    if (!Array.isArray(sheetData)) return null;

    // Filtrar la hoja "Complementary"
    if (selectedSheet.sheetName === 'Complementary') {
      return null; // No mostrar la tabla si es "Complementary"
    }

    // Si es la hoja DCF, aplicar formato especial
    if (selectedSheet.sheetName === 'DCF') {
      return sheetData
        .filter((row, index) => {
          if (index === sheetData.length - 1) return false;
          if (!row || !Array.isArray(row)) return false;
          return true;
        })
        .map((row, rowIndex) => {
          if (!row || !Array.isArray(row)) return null;

          const isSpecialRow = [
            'TERMINAL VALUE',
            'NPV of FCF',
            'NPV of TV',
            'Total EV',
            'Net Debt',
            'Equity',
            'Shares Outstanding',
            'Target Price'
          ].includes(row[0]);

          const isHeaderRow = rowIndex === 0;
          const isUnleveredRow = row[0] === 'Unlevered Free Cash Flow';
          const isTotalDebtRow = row[0] === 'Total Debt';

          // Reordenar las columnas para la fila de encabezado
          let displayRow;
          if (isHeaderRow) {
            // Crear el nuevo orden de años: 2021-2030
            displayRow = ['Concept'];
            for (let year = 2021; year <= 2030; year++) {
              displayRow.push(year.toString());
            }
          } else {
            // Para las demás filas, reordenar los valores según el nuevo orden de años
            const values = [...row];
            const label = values.shift(); // Obtener la etiqueta
            displayRow = [label, ...values.slice(-10)]; // Tomar los últimos 10 valores
          }

          return (
            <tr key={rowIndex} className={`
              ${isSpecialRow ? 'special-row' : ''}
              ${isHeaderRow ? 'header-row' : ''}
              ${isUnleveredRow ? 'unlevered-row' : ''}
              ${isTotalDebtRow ? 'total-debt-row' : ''}
            `}>
              {displayRow.map((cell, cellIndex) => {
                let formattedValue = cell;
                if (!isHeaderRow && cellIndex > 0) {
                  const num = parseFloat(cell);
                  if (!isNaN(num)) {
                    formattedValue = formatYahooNumber(num);
                  }
                }

                return (
                  <td key={cellIndex} className={`
                    ${cellIndex === 0 ? 'label-column' : 'value-column'}
                    ${isHeaderRow ? 'header-cell' : ''}
                    ${!isNaN(parseFloat(formattedValue)) && parseFloat(formattedValue) < 0 ? 'negative-value' : ''}
                  `}>
                    {formattedValue}
                  </td>
                );
              })}
            </tr>
          );
        }).filter(Boolean);
    }

    // Para otras hojas, mantener el renderizado original
    return sheetData.map((row, rowIndex) => {
      if (!row) return null;

      // Si es un array, procesarlo directamente
      if (Array.isArray(row)) {
        return (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{formatValue(cell, rowIndex)}</td>
            ))}
          </tr>
        );
      }

      // Si es un objeto, procesarlo como antes
      const description = Object.keys(row)[0];
      const values = Object.values(row)[0];

      if (!description || !Array.isArray(values)) return null;

      return (
        <tr key={rowIndex}>
          <td>{description}</td>
          {values.map((value, index) => (
            <td key={index}>{formatValue(value, rowIndex)}</td>
          ))}
        </tr>
      );
    }).filter(Boolean);
  };

  return (
    <div className="stock-detail-container">
      <aside className="sidebar">
        <h2>Menu</h2>
        <button
          className={`sidebar-button slider-button ${showCharts ? 'show-charts' : 'show-tables'}`}
          onClick={toggleView}
        >
          {showCharts ? 'Show Tables' : 'Show Charts'}
        </button>
        
        <button
          className={`sidebar-button ${showNews ? 'active' : ''}`}
          onClick={() => setShowNews(!showNews)}
        >
          News
        </button>

        {filteredData
          .filter(sheet => sheet.sheetName !== 'Financial Data' && sheet.sheetName !== 'Complementary')
          .map((sheet, index) => {
            const isDCF = sheet.sheetName === 'DCF';
            const isPremiumUser = user && user.isPremium;
            return (
              <button
                key={index}
                onClick={() => {
                  handleSheetSelect(index);
                  setShowNews(false);
                }}
                className={`sidebar-button ${isDCF && !isPremiumUser ? 'disabled' : ''}`}
                disabled={isDCF && !isPremiumUser}
              >
                {sheet.sheetName} {isDCF && !isPremiumUser ? '(Premium)' : ''}
              </button>
            );
          })}
      </aside>

      <main className="main-content">
        {canViewDetails() ? (
          <>
            <div className="stock-info">
              <h2>{ticker}</h2>
              <h1>{companyName}</h1>
              <div className="stock-info-row">
                <h1>Current price: {currentPrice !== null ? `$${formatYahooNumber(currentPrice)}` : 'Loading...'}</h1>
                <h1>Target price: {targetPrice !== null ? `$${formatYahooNumber(targetPrice)}` : 'Loading...'}</h1>
                <h1 className={upside >= 0 ? 'upside-positive' : 'upside-negative'}>
                  Upside: {upside !== null ? `${formatYahooNumber(upside)}%` : 'Loading...'}
                </h1>
                <h1>Volume: {volume !== null ? formatYahooNumber(volume) : 'Loading...'}</h1>
                <h1>PE Ratio: {peRatio !== null ? formatYahooNumber(peRatio) : 'Loading...'}</h1>
                <h1>Dividend: {dividendRate !== null ? `$${formatYahooNumber(dividendRate)}` : 'Loading...'}</h1>
                <h1>EPS: {eps !== null ? formatYahooNumber(eps) : 'Loading...'}</h1>
                <h1>Book value: {bookValue !== null ? `$${formatYahooNumber(bookValue)}` : 'Loading...'}</h1>
                <h1>Market Cap: {marketCap !== null ? formatYahooNumber(marketCap) : 'Loading...'}</h1>
                <button onClick={handleToggleStockInPortfolio} disabled={loadingSave || loadingDelete}>
                  {loadingSave || loadingDelete 
                    ? (loadingSave ? 'Saving...' : 'Removing...') 
                    : (isStockInPortfolio ? 'Remove from Portfolio' : 'Add to Portfolio')}
                </button>
              </div>
            </div>
            {error && <p className="error">{error}</p>}

            {showNews ? (
              <div className="data-card">
                <h3>Latest News</h3>
                <div className="news-grid">
                  {news.map((item, index) => (
                    <div key={index} className="news-card">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                        Read more
                      </a>
                      <span className="news-date">
                        {new Date(item.providerPublishTime * 1000).toLocaleDateString()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="data-card">
                <h3>{selectedSheet.sheetName} - Numbers in $ millions</h3>

                {showCharts ? (
                  <ChartComponent data={filteredSheetData} /> 
                ) : (
                  <div className="table-container">
                    <table>
                      <tbody>
                        {renderTableData(filteredSheetData)}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="limit-reached-message">
            <h2>Límite de búsquedas alcanzado</h2>
            <p>Has alcanzado el límite de 10 búsquedas gratuitas. Para continuar viendo detalles de stocks, por favor:</p>
            <div className="auth-buttons">
              <Link to="/register" className="auth-button">Registrarse</Link>
              <Link to="/login" className="auth-button">Iniciar Sesión</Link>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};


export default StockDetail;
