import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import './Screener.css';

const Screener = () => {
  const [stockTableData, setStockTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    country: '',
    upside: 'both',
    marketCapRange: '',
    epsRange: '',
    bookValueRange: '',
    business: '',
    netDebtGrowthRange: '',
    totalAssetsGrowthRange: '',
    netPropertyGrowthRange: '',
    ebitdaGrowthRange: ''
  });
  const [filterEnabled, setFilterEnabled] = useState({
    country: true,
    business: true,
    upside: true,
    marketCapRange: true,
    epsRange: true,
    bookValueRange: true,
    netDebtGrowthRange: true,
    totalAssetsGrowthRange: true,
    netPropertyGrowthRange: true,
    ebitdaGrowthRange: true,
  });

  // Memoizar los rangos para evitar recreaciones innecesarias
  const ranges = useMemo(() => ({
    marketCap: [
      { label: 'All', value: '' },
      { label: 'Micro < 300M', value: '0-300000000' },
      { label: 'Small 300M - 2B', value: '300000000-2000000000' },
      { label: 'Mid 2B - 10B', value: '2000000000-10000000000' },
      { label: 'Large 10B - 100B', value: '10000000000-100000000000' },
      { label: 'Mega > 100B', value: '100000000000-999999999999' }
    ],
    eps: [
      { label: 'All', value: '' },
      { label: 'Negative < 0', value: '-999999-0' },
      { label: '0 - 1', value: '0-1' },
      { label: '1 - 5', value: '1-5' },
      { label: '5 - 20', value: '5-20' },
      { label: '> 20', value: '20-999999' }
    ],
    bookValue: [
      { label: 'All', value: '' },
      { label: '< 5', value: '0-5' },
      { label: '5 - 20', value: '5-20' },
      { label: '20 - 50', value: '20-50' },
      { label: '50 - 100', value: '50-100' },
      { label: '> 100', value: '100-999999' }
    ],
    netDebtGrowth: [
      { label: 'All', value: '' },
      { label: '< 0%', value: '-999999-0' },
      { label: '0% - 20%', value: '0-20' },
      { label: '20% - 50%', value: '20-50' },
      { label: '> 50%', value: '50-999999' }
    ],
    totalAssetsGrowth: [
      { label: 'All', value: '' },
      { label: '< 0%', value: '-999999-0' },
      { label: '0% - 20%', value: '0-20' },
      { label: '20% - 50%', value: '20-50' },
      { label: '> 50%', value: '50-999999' }
    ],
    netPropertyGrowth: [
      { label: 'All', value: '' },
      { label: '< 0%', value: '-999999-0' },
      { label: '0% - 20%', value: '0-20' },
      { label: '20% - 50%', value: '20-50' },
      { label: '> 50%', value: '50-999999' }
    ],
    ebitdaGrowth: [
      { label: 'All', value: '' },
      { label: '< 0%', value: '-999999-0' },
      { label: '0% - 20%', value: '0-20' },
      { label: '20% - 50%', value: '20-50' },
      { label: '> 50%', value: '50-999999' }
    ]
  }), []);
  

  // Memoizar la función de limpieza de datos
  const cleanAndCombineData = useCallback((data) => {
    const parseNumber = (value) => {
      if (value === '#N/A' || value === undefined || value === '') return null;
      const num = parseFloat(value);
      return isNaN(num) ? null : num;
    };

    return data.map(row => ({
      ticker: row['Ticker'],
      name: row['Name'],
      business: row['Business'] || row['Bussines'] || '',
      targetPrice: parseNumber(row['Valuation']),
      upside: parseNumber(row['Valuation/Current Price - 1']),
      actualPrice: parseNumber(row['Market Price']),
      marketCap: parseNumber(row['Market cap']),
      eps: parseNumber(row['EPS']),
      bookValue: parseNumber(row['Book Value']),
      country: row['Country'] || '',
      netDebtGrowth: parseNumber(row['Net Debt Growth']),
      totalAssetsGrowth: parseNumber(row['Total Assets Growth']),
      netPropertyGrowth: parseNumber(row['Net Property, Plant and Equipment Growth']),
      ebitdaGrowth: parseNumber(row['EBITDA Growth'])
    }));
  }, []);

  // Memoizar la función de filtrado
  const filterData = useCallback(() => {
    let filtered = [...stockTableData];

    console.log("Datos originales:", stockTableData);

    const filterByRange = (value, range) => {
      if (!range) return true;
      const [min, max] = range.split('-').map(Number);
      return value >= min && value <= max;
    };

    if (filterEnabled.country && filters.country) {
      filtered = filtered.filter(stock => stock.country === filters.country);
    }
    if (filterEnabled.business && filters.business) {
      filtered = filtered.filter(stock => stock.business === filters.business);
    }
    if (filterEnabled.upside && filters.upside !== 'both') {
      filtered = filtered.filter(stock => 
        filters.upside === 'positive' ? stock.upside > 0 : stock.upside < 0
      );
    }
    if (filterEnabled.marketCapRange && filters.marketCapRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.marketCap, filters.marketCapRange)
      );
    }
    if (filterEnabled.epsRange && filters.epsRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.eps, filters.epsRange)
      );
    }
    if (filterEnabled.bookValueRange && filters.bookValueRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.bookValue, filters.bookValueRange)
      );
    }
    if (filterEnabled.netDebtGrowthRange && filters.netDebtGrowthRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.netDebtGrowth, filters.netDebtGrowthRange)
      );
    }
    if (filterEnabled.totalAssetsGrowthRange && filters.totalAssetsGrowthRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.totalAssetsGrowth, filters.totalAssetsGrowthRange)
      );
    }
    if (filterEnabled.netPropertyGrowthRange && filters.netPropertyGrowthRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.netPropertyGrowth, filters.netPropertyGrowthRange)
      );
    }
    if (filterEnabled.ebitdaGrowthRange && filters.ebitdaGrowthRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.ebitdaGrowth, filters.ebitdaGrowthRange)
      );
    }

    console.log("Datos filtrados:", filtered);

    setFilteredData(filtered);
  }, [filters, stockTableData, filterEnabled]);

  // Cargar datos con manejo de caché
  const loadStockData = useCallback(async () => {
    setIsLoading(true);
    try {
      // Intentar obtener datos de caché
      const cachedData = localStorage.getItem('stockData');
      const cachedTimestamp = localStorage.getItem('stockDataTimestamp');
      const CACHE_DURATION = 5 * 60 * 1000; // 5 minutos

      if (cachedData && cachedTimestamp && Date.now() - parseInt(cachedTimestamp) < CACHE_DURATION) {
        const parsedData = JSON.parse(cachedData);
        setStockTableData(parsedData);
        setFilteredData(parsedData);
        processUniqueValues(parsedData);
      } else {
        const response = await axios.get('https://eyeopeningstocks.com/backend/resultados');
        const fileData = response.data;

        console.log("Datos recibidos:", fileData);

        if (fileData && fileData.length > 0) {
          const stockData = cleanAndCombineData(fileData);
          
          // Guardar en caché
          localStorage.setItem('stockData', JSON.stringify(stockData));
          localStorage.setItem('stockDataTimestamp', Date.now().toString());

          setStockTableData(stockData);
          setFilteredData(stockData);
          processUniqueValues(stockData);
        }
      }
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [cleanAndCombineData]);

  // Función para procesar valores únicos
  const processUniqueValues = useCallback((data) => {
    const countries = [...new Set(data
      .map(stock => stock.country)
      .filter(country => 
        country && 
        isNaN(country) && 
        !/^\d+$/.test(country)
      ))];
    const types = [...new Set(data.map(stock => stock.business).filter(Boolean))];
    
    setUniqueCountries(countries);
    setUniqueTypes(types);
  }, []);

  useEffect(() => {
    loadStockData();
  }, [loadStockData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Añade esta función dentro del componente Screener, antes del return
  const handleRowClick = (ticker, name) => {
    const formattedName = `${ticker} - ${name}`;
    const encodedName = encodeURIComponent(formattedName);
    window.location.href = `https://eyeopeningstocks.com/search/${encodedName}`;
  };

  // Memoizar el renderizado de la tabla
  const TableContent = useMemo(() => (
    <tbody>
      {filteredData.length > 0 ? (
        filteredData.map((stock, index) => (
          <tr 
            key={index}
            onClick={() => handleRowClick(stock.ticker, stock.name)}
            style={{ cursor: 'pointer' }}
            className="stock-row"
          >
            <td>{stock.ticker}</td>
            <td>{stock.name}</td>
            {filterEnabled.business && <td>{stock.business}</td>}
            {filterEnabled.country && <td>{stock.country}</td>}
            {filterEnabled.upside && <td>{stock.upside !== null ? `${(stock.upside * 100).toFixed(2)}%` : '-'}</td>}
            {filterEnabled.marketCapRange && <td>{stock.marketCap !== null ? formatMarketCap(stock.marketCap) : '-'}</td>}
            {filterEnabled.epsRange && <td>{stock.eps !== null ? stock.eps.toFixed(2) : '-'}</td>}
            {filterEnabled.bookValueRange && <td>{stock.bookValue !== null ? stock.bookValue.toFixed(2) : '-'}</td>}
            {filterEnabled.netDebtGrowthRange && <td>{stock.netDebtGrowth !== null ? (stock.netDebtGrowth).toFixed(2) : '-'}</td>}
            {filterEnabled.totalAssetsGrowthRange && <td>{stock.totalAssetsGrowth !== null ? (stock.totalAssetsGrowth).toFixed(2) : '-'}</td>}
            {filterEnabled.netPropertyGrowthRange && <td>{stock.netPropertyGrowth !== null ? (stock.netPropertyGrowth).toFixed(2) : '-'}</td>}
            {filterEnabled.ebitdaGrowthRange && <td>{stock.ebitdaGrowth !== null ? (stock.ebitdaGrowth).toFixed(2) : '-'}</td>}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="14">{isLoading ? 'Loading...' : 'No results found'}</td>
        </tr>
      )}
    </tbody>
  ), [filteredData, isLoading, filterEnabled]);

  const TableHeader = () => (
    <thead>
      <tr>
        <th style={{ width: '80px' }}>Ticker</th>
        <th style={{ width: '150px' }}>Name</th>
        {filterEnabled.business && <th style={{ width: '100px' }}>Sector</th>}
        {filterEnabled.country && <th style={{ width: '100px' }}>Country</th>}
        {filterEnabled.upside && <th style={{ width: '100px' }}>Upside (%)</th>}
        {filterEnabled.marketCapRange && <th style={{ width: '100px' }}>Market Cap</th>}
        {filterEnabled.epsRange && <th style={{ width: '80px' }}>EPS</th>}
        {filterEnabled.bookValueRange && <th style={{ width: '100px' }}>Book Value</th>}
        {filterEnabled.netDebtGrowthRange && <th style={{ width: '150px' }}>Net Debt Growth (%)</th>}
        {filterEnabled.totalAssetsGrowthRange && <th style={{ width: '150px' }}>Total Assets Growth (%)</th>}
        {filterEnabled.netPropertyGrowthRange && <th style={{ width: '150px' }}>Net Property Growth (%)</th>}
        {filterEnabled.ebitdaGrowthRange && <th style={{ width: '150px' }}>EBITDA Growth (%)</th>}
      </tr>
    </thead>
  );

  return (
    <div className="screener-container">
      <h1>Stock Screener</h1>

      <div className="filters-container">
        <div className="filters-grid">
          <div className="filter-group">
            <label>
              Country
              <input 
                type="checkbox" 
                checked={filterEnabled.country} 
                onChange={() => setFilterEnabled({...filterEnabled, country: !filterEnabled.country})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.country}
              onChange={(e) => setFilters({...filters, country: e.target.value})}
            >
              <option value="">All Countries</option>
              {uniqueCountries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              Sector
              <input 
                type="checkbox" 
                checked={filterEnabled.business} 
                onChange={() => setFilterEnabled({...filterEnabled, business: !filterEnabled.business})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.business}
              onChange={(e) => setFilters({...filters, business: e.target.value})}
            >
              <option value="">All Sectors</option>
              {uniqueTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              Upside
              <input 
                type="checkbox" 
                checked={filterEnabled.upside} 
                onChange={() => setFilterEnabled({...filterEnabled, upside: !filterEnabled.upside})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.upside}
              onChange={(e) => setFilters({...filters, upside: e.target.value})}
            >
              <option value="both">All</option>
              <option value="positive">Positive</option>
              <option value="negative">Negative</option>
            </select>
          </div>

          <div className="filter-group">
            <label>
              Market Cap
              <input 
                type="checkbox" 
                checked={filterEnabled.marketCapRange} 
                onChange={() => setFilterEnabled({...filterEnabled, marketCapRange: !filterEnabled.marketCapRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.marketCapRange}
              onChange={(e) => setFilters({...filters, marketCapRange: e.target.value})}
            >
              {ranges.marketCap.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              EPS
              <input 
                type="checkbox" 
                checked={filterEnabled.epsRange} 
                onChange={() => setFilterEnabled({...filterEnabled, epsRange: !filterEnabled.epsRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.epsRange}
              onChange={(e) => setFilters({...filters, epsRange: e.target.value})}
            >
              {ranges.eps.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              Book Value
              <input 
                type="checkbox" 
                checked={filterEnabled.bookValueRange} 
                onChange={() => setFilterEnabled({...filterEnabled, bookValueRange: !filterEnabled.bookValueRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.bookValueRange}
              onChange={(e) => setFilters({...filters, bookValueRange: e.target.value})}
            >
              {ranges.bookValue.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              Net Debt Growth
              <input 
                type="checkbox" 
                checked={filterEnabled.netDebtGrowthRange} 
                onChange={() => setFilterEnabled({...filterEnabled, netDebtGrowthRange: !filterEnabled.netDebtGrowthRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.netDebtGrowthRange}
              onChange={(e) => setFilters({...filters, netDebtGrowthRange: e.target.value})}
            >
              {ranges.netDebtGrowth.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              Total Assets Growth
              <input 
                type="checkbox" 
                checked={filterEnabled.totalAssetsGrowthRange} 
                onChange={() => setFilterEnabled({...filterEnabled, totalAssetsGrowthRange: !filterEnabled.totalAssetsGrowthRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.totalAssetsGrowthRange}
              onChange={(e) => setFilters({...filters, totalAssetsGrowthRange: e.target.value})}
            >
              {ranges.totalAssetsGrowth.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              Net Property Growth
              <input 
                type="checkbox" 
                checked={filterEnabled.netPropertyGrowthRange} 
                onChange={() => setFilterEnabled({...filterEnabled, netPropertyGrowthRange: !filterEnabled.netPropertyGrowthRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.netPropertyGrowthRange}
              onChange={(e) => setFilters({...filters, netPropertyGrowthRange: e.target.value})}
            >
              {ranges.netPropertyGrowth.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>
              EBITDA Growth
              <input 
                type="checkbox" 
                checked={filterEnabled.ebitdaGrowthRange} 
                onChange={() => setFilterEnabled({...filterEnabled, ebitdaGrowthRange: !filterEnabled.ebitdaGrowthRange})} 
                style={{ marginLeft: '5px' }}
              />
            </label>
            <select 
              value={filters.ebitdaGrowthRange}
              onChange={(e) => setFilters({...filters, ebitdaGrowthRange: e.target.value})}
            >
              {ranges.ebitdaGrowth.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="results-counter">
        <p>Showing {filteredData.length} results</p>
      </div>

      <div className="table-container">
        <div className="scrollable-table">
          <table className="stock-table">
            <TableHeader />
            {TableContent}
          </table>
        </div>
      </div>
    </div>
  );
};

// Memoizar la función de formato
const formatMarketCap = (value) => {
  if (!value) return '-';
  if (value >= 1000000000000) {
    return `${(value / 1000000000000).toFixed(2)}T`;
  }
  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(2)}B`;
  }
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(2)}M`;
  }
  return `${value.toFixed(2)}`;
};

export default Screener;
