// src/components/Main.js
import React from 'react';
import './Main.css'; 
import { BrowserRouter as Router } from 'react-router-dom';


const Main = () => {
  return (
      <main style={styles.container}>
        <header style={styles.header}>
          <h1 style={styles.title}>Welcome to Open Eyes stock</h1>
          <p style={styles.description}>
          Here you will find valuable information about the markets and financial analysis.
          </p>
        </header>
        <section style={styles.cardContainer}>
          <Card 
            title="Stock Analysis"
            description="Access our powerful stock analysis tool."
            link="https://eyeopeningstocks.com/search/AAPL%20-%20APPLE%20INC"
          />
          <Card 
            title="Screener"
            description="Use our screener to filter and find the best investment opportunities."
            link="/screener"
          />
        </section>
      </main>
  );
};

const Card = ({ title, description, link }) => {
  return (
    <div style={styles.card}>
      <h2 style={styles.cardTitle}>{title}</h2>
      <p style={styles.cardDescription}>{description}</p>
      <a href={link} style={styles.cardLink}>Go to</a>
    </div>
  );
};

const styles = {
  container: {
    padding: '40px 20px',
    minHeight: '100vh',
    backgroundImage: 'linear-gradient(rgba(13, 17, 23, 0.9), rgba(13, 17, 23, 0.85)), url("/images/OIP.jfif")', 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  },
  header: {
    textAlign: 'center',
    marginBottom: '60px',
    padding: '30px',
    backdropFilter: 'blur(8px)',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    border: 'none',
  },
  title: {
    color: '#f8fafc',
    fontSize: '42px',
    fontWeight: '700',
    marginBottom: '20px',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
  description: {
    fontSize: '20px',
    color: '#cbd5e1',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    flexWrap: 'wrap',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  card: {
    backgroundColor: 'rgba(30, 41, 59, 0.95)',
    borderRadius: '20px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
    padding: '35px',
    margin: '10px',
    width: '340px',
    textAlign: 'center',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(71, 85, 105, 0.3)',
    '&:hover': {
      transform: 'translateY(-10px)',
      boxShadow: '0 15px 30px rgba(0, 0, 0, 0.4)',
      backgroundColor: 'rgba(30, 41, 59, 0.98)',
    },
  },
  cardTitle: {
    fontSize: '28px',
    margin: '15px 0',
    color: '#f8fafc',
    fontWeight: '600',
  },
  cardDescription: {
    color: '#cbd5e1',
    margin: '15px 0',
    fontSize: '16px',
    lineHeight: '1.6',
  },
  cardLink: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#ffffff',
    fontWeight: '500',
    backgroundColor: '#0ea5e9',
    padding: '12px 30px',
    borderRadius: '25px',
    marginTop: '15px',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(71, 85, 105, 0.3)',
    '&:hover': {
      backgroundColor: '#0284c7',
      transform: 'scale(1.05)',
      boxShadow: '0 4px 12px rgba(14, 165, 233, 0.3)',
    },
  },
};


export default Main;
